/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react'

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { SalesItem } from '../../../models/SalesItem'
import { SalesOrder } from '../../../models/SalesOrder'
import { Transaction } from '../../../models/Transaction'
import { formatPrice, getTransactionLabel } from '../../../utils'
import Loading from '../../base/Loading'
import CustomerData from '../../orders/CustomerData'
import NotesStacked from '../../orders/NotesStacked'
import OrderItems from '../../orders/OrderItems'
import OrderMetaData from '../../orders/OrderMetaData'
import RefundCard from '../../orders/refunds/RefundCard'
import TagsStacked from '../../orders/TagsStacked'
import ReturnRefundModal from '../ReturnRefundModal'

const ReturnRefundForm = () => {
  const { orderName, returnId } = useParams()
  const navigate = useNavigate()
  const [isRefundedOrderModalVisible, setRefundedOrderModalVisibility] = useState<boolean>(false)
  const [showLoading, setLoading] = useState<boolean>(true)
  const [selected, setSelected] = useState<boolean>(false)
  const [notify, setNotify] = useState<boolean>(true)
  const [shippingFee, setShippingFee] = useState<boolean>(false)
  const order = useSelector((state: any) => state.order.value)
  const customer = useSelector((state: any) => state.order.customer)
  const tmpOrderName = useSelector((state: any) => state.order.name)
  const [salesOrder, setSalesOrder] = useState<SalesOrder | null>(order)
  const [totalOfItems, setTotalOfItems] = useState<number>(0)
  const [subTotalItemPrice, setSubTotalItemPrice] = useState<number>(0)
  const [taxPrice, setTaxPrice] = useState<number>(0)
  const [returnStatus, setReturnStatus] = useState<number>(0)
  const [tmpTransactions, setTransactions] = useState<Transaction[]>([])
  const tmpRefundValue = useRef(0)
  const [returnedOrderItems, setReturnedOrderItems] = useState<SalesItem[]>([])
  const [returnOrder, setReturn] = useState<any>()
  let tmpReturnedOrderItems: any = []

  const countryCode = customer._shippingAddress.countryCode
    ? customer._shippingAddress.countryCode
    : null || customer._billingAddress.countryCode
    ? customer._billingAddress.countryCode
    : null
  const currencyCode = order._currencyCode

  // Set the item quantity based on the unselected/selected all checkbox and set the state for the order object
  useEffect(() => {
    order._returns.map((value: any, idx: number) => {
      if (value.id.toString() === returnId) {
        tmpReturnedOrderItems = value.lineItems
        setReturnStatus(order._rmaRequests[idx].current_return_status)
        setReturn(value)
        setReturnedOrderItems(tmpReturnedOrderItems)
      }
    })
    setSalesOrder(order)
    setLoading(false)
    const tmpArr: Transaction[] = []
    order._transactions
      .filter((transaction: Transaction) => {
        return (
          transaction.status === 'SUCCESS' &&
          (transaction.kind === 'SALE' || transaction.kind === 'CAPTURE')
        )
      })
      .map((transaction: Transaction) => {
        tmpArr.push(transaction)
      })
    setTransactions([...tmpArr])
  }, [])

  useEffect(() => {
    if (tmpOrderName !== orderName || order._financialStatus === 'REFUNDED') {
      navigate(`/orders/${orderName}/`)
    }
  }, [location.pathname])

  useEffect(() => {
    disableButton()
  }, [tmpTransactions])

  const handleRefundOrderModalVisibility = () => {
    setRefundedOrderModalVisibility(!isRefundedOrderModalVisible)
  }

  const handleItemQuantitySelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.checked)
    returnedOrderItems.map((item: SalesItem) => {
      event.target.checked
        ? (item.currentShopifyQuantity = item.initialQuantity)
        : (item.currentShopifyQuantity = 0)
    })
  }

  const disableButton = () => {
    return getRefundAmount() === 0 || isInvalidInput()
  }

  const isInvalidInput = () => {
    let overlimit = false
    tmpTransactions.map((transaction: Transaction) => {
      if (transaction.tmpAmount > transaction.amount - transaction.refundableAmount) {
        overlimit = true
      }
    })
    return overlimit
  }

  const isItemSelected = () => {
    let isSelectedItems = true
    if (returnedOrderItems.length > 0) {
      returnedOrderItems.map((element: SalesItem) => {
        if (element.currentShopifyQuantity < element.initialQuantity) {
          isSelectedItems = false
        }
      })
    }
    return isSelectedItems
  }

  const getSelectedItems = () => {
    const tmpOrderItems: any[] = []
    if (returnedOrderItems.length > 0) {
      returnedOrderItems.map((element: SalesItem) => {
        if (element.currentShopifyQuantity > 0) {
          tmpOrderItems.push({
            return_line_item_id: element.returnId,
            quantity: element.currentShopifyQuantity,
            srcImage: element.srcImage,
            sku: element.sku,
            price:
              parseFloat(element.discountedPrice).toFixed(2) !== '0.00'
                ? parseFloat(element.tmpPrice) -
                  parseFloat(element.discountedPrice) / element.initialQuantity
                : element.tmpPrice,
            title: element.title,
            variantTitle: element.variantTitle,
          })
        }
      })
    }
    return tmpOrderItems
  }

  const getShippingPrice = () => {
    if (isItemSelected() || shippingFee) {
      return order._totalShippingPriceSet
    } else {
      return 0
    }
  }

  const getRefundTotal = (refundTotal: number) => {
    if (shippingFee) {
      refundTotal += parseFloat(order._totalShippingPriceSet)
    }
    // This condition uses net payments to limit the amount refunded in paid orders.
    //  Avoid refund limits in authorized orders.
    if (parseFloat(order._netPaymentSet) < refundTotal && order._financialStatus !== 'AUTHORIZED') {
      refundTotal =
        parseFloat(order._netPaymentSet) > 0 ? order._netPaymentSet : order._currentTotalPriceSet
    }
    tmpRefundValue.current = Math.round(refundTotal * 100) / 100
    return tmpRefundValue.current
  }

  const getItemTax = (taxRates: number[], tmpPrice: number, quantity: number) => {
    let taxPrice: number = 0
    taxRates.map((taxRate: number) => {
      taxPrice += taxRate * tmpPrice * quantity
    })
    return parseFloat(taxPrice.toFixed(2))
  }

  const handleItemQuantity = () => {
    let tmpTotalOfItems = 0
    let tmpSubTotalItem = 0
    let tmpTaxPrice = 0
    returnedOrderItems.map((item: SalesItem) => {
      const price =
        parseFloat(item.discountedPrice).toFixed(2) !== '0.00'
          ? parseFloat(item.tmpPrice) - parseFloat(item.discountedPrice) / item.initialQuantity
          : parseFloat(item.tmpPrice)
      const tmpPrice = price === -Infinity || price === Infinity ? 0 : price
      tmpTotalOfItems += item.currentShopifyQuantity
      tmpSubTotalItem += item.currentShopifyQuantity * tmpPrice
      tmpTaxPrice += getItemTax(item.taxRates, tmpPrice, item.currentShopifyQuantity)
    })
    setTaxPrice(tmpTaxPrice)
    setSubTotalItemPrice(tmpSubTotalItem)
    setTotalOfItems(tmpTotalOfItems)
  }

  const helperRefundAvailableText = (maxRefundInput: number) => {
    return formatPrice(maxRefundInput.toString(), currencyCode, customer) + ' available for refund'
  }

  const getRefundAmount = () => {
    let tmpAmount = 0
    tmpTransactions.some((transaction: any) => {
      if (!isNaN(+transaction.tmpAmount) && transaction.tmpAmount !== '') {
        tmpAmount += parseFloat(transaction.tmpAmount)
      }
    })
    return tmpAmount
  }

  const updateFieldChanged = (event: any, idx: number) => {
    const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')
    const value = event.target.value
    const newArr = [...tmpTransactions]
    if (value === '' || floatRegExp.test(value)) {
      newArr[idx].tmpAmount = value
    } else {
      newArr[idx].tmpAmount = 0
    }
    setTransactions(newArr)
  }

  const getCurrencySymbol = () => {
    return (0)
      .toLocaleString(`en-${countryCode ? countryCode : null}`, {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim()
  }

  return (
    <Box
      sx={{
        p: 4,
        marginLeft: '-32px',
        paddingBottom: '120px',
      }}
    >
      {showLoading && <Loading />}
      <ReturnRefundModal
        isShown={isRefundedOrderModalVisible}
        onClose={handleRefundOrderModalVisibility}
        orderItems={getSelectedItems()}
        subTotalPrice={subTotalItemPrice.toString()}
        shippingPrice={getShippingPrice()}
        taxPrice={taxPrice.toFixed(2)}
        totalOfItems={totalOfItems}
        refundTotal={getRefundAmount()}
        notify={notify}
        transactions={tmpTransactions}
        returnOrder={returnOrder}
      />
      {!showLoading && salesOrder && (
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          columns={12}
          spacing={2}
          sx={{
            margin: 'auto',
            maxWidth: 1400,
            flexGrow: 1,
          }}
        >
          <Grid item xs={12} sm={12} md={9} lg={8}>
            <Paper
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
              }}
            >
              <OrderMetaData order={salesOrder} />
              <Grid
                container
                direction='column'
                justifyContent='flex-end'
                alignItems='flex-end'
                spacing={2}
              >
                <FormControlLabel
                  control={<Checkbox checked={selected} onChange={handleItemQuantitySelection} />}
                  label={selected ? 'Deselect All' : 'Select All'}
                  labelPlacement='end'
                />
              </Grid>
              <Grid>
                <OrderItems
                  orderItems={returnedOrderItems}
                  extraStatus={returnStatus}
                  status={'Return'}
                  canDuplicateOrder={true}
                  selectedItems={setSelected}
                  order={salesOrder}
                  customer={customer}
                  handleItemQuantity={handleItemQuantity}
                />
              </Grid>
            </Paper>
            <Paper
              sx={{
                paddingBottom: '10px',
                margin: '20px 0px 12px ',
              }}
            >
              <Grid container direction='row' justifyContent='flex-end' alignItems='flex-end'>
                <RefundCard
                  totalOfItems={totalOfItems}
                  subTotalPrice={subTotalItemPrice.toString()}
                  order={salesOrder}
                  customer={customer}
                  shippingPrice={getShippingPrice()}
                  taxPrice={taxPrice.toFixed(2)}
                  refundTotal={getRefundTotal(subTotalItemPrice)}
                  setTransactions={setTransactions}
                  tmpTransactions={tmpTransactions}
                />
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  paddingLeft={3.5}
                >
                  <Grid item xs={8}>
                    <FormControlLabel
                      sx={{ fontWeight: 400 }}
                      control={
                        <Checkbox onChange={(event) => setShippingFee(event.target.checked)} />
                      }
                      label={
                        <Typography>
                          Add shipping fee (
                          <strong>
                            {formatPrice(order._totalShippingPriceSet, currencyCode, customer)}
                          </strong>
                          )
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction='column'
                  justifyContent='flex-end'
                  alignItems='flex-end'
                  sx={{ padding: '0 30px' }}
                >
                  <Grid item xs={3}>
                    <Typography sx={{ fontWeight: 400, paddingBottom: '16px' }}>
                      <strong>Refund total</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography sx={{ fontWeight: 400 }}>{''}</Typography>
                  </Grid>
                  {tmpTransactions.map((transaction: Transaction, idx: number) => (
                    <Grid item xs={4} justifyContent='flex-end' key={transaction.id}>
                      <Typography>{getTransactionLabel(transaction)}</Typography>
                      <TextField
                        id='refund-input'
                        name={`transactions[${idx}]`}
                        sx={{
                          width: 'auto',
                          maxWidth: '360px',
                          float: 'right',
                          alignItems: 'flex-end',
                          input: {
                            textAlign: 'right',
                          },
                        }}
                        inputMode='numeric'
                        size='small'
                        value={transaction.tmpAmount}
                        onChange={(e) => updateFieldChanged(e, idx)}
                        onKeyDown={(event) => {
                          const key = window.event ? event.keyCode : event.which
                          // Allow only backspace, delete, left arrow, right arrow and period
                          if (
                            event.keyCode == 8 ||
                            event.keyCode == 46 ||
                            event.keyCode == 37 ||
                            event.keyCode == 39 ||
                            event.keyCode === 190
                          ) {
                            return true
                            // Make sure it's a number
                          } else if (key < 48 || key > 57) {
                            event.preventDefault()
                            return false
                          }
                        }}
                        variant='standard'
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            fontSize: '1.25rem',
                            fontWeight: 700,
                          },
                        }}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            max: transaction.amount - transaction.refundableAmount,
                            step: '0.01',
                          },
                          style: {
                            width: 'auto',
                            maxWidth: '200px',
                            marginTop: '24px',
                            textAlign: 'right',
                          },
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Typography sx={{ paddingBottom: '6px' }}>
                                {getCurrencySymbol()}
                              </Typography>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Typography sx={{ paddingBottom: '4px' }}>
                                {currencyCode !== 'USD' ? ' ' + currencyCode : ''}
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                        helperText={helperRefundAvailableText(
                          transaction.amount - transaction.refundableAmount,
                        )}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  paddingLeft={3.5}
                >
                  <Grid item xs={8}>
                    <FormControlLabel
                      sx={{ fontWeight: 400 }}
                      control={
                        <Checkbox
                          onChange={(event) => setNotify(event.target.checked)}
                          defaultChecked
                        />
                      }
                      label='Send notification to customer'
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='flex-end'
                  paddingLeft={3}
                  paddingRight={3}
                  paddingBottom={3}
                >
                  <Grid item>
                    <Button
                      type='submit'
                      color='primary'
                      size='large'
                      variant='contained'
                      disabled={disableButton()}
                      onClick={handleRefundOrderModalVisibility}
                    >
                      Refund
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={4}>
            <Paper
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
              }}
            >
              <CustomerData customer={customer} />
              <TagsStacked tags={salesOrder._tags} />
            </Paper>
            <Paper
              sx={{
                p: 2,
                margin: '10px auto',
                flexGrow: 1,
              }}
            >
              <NotesStacked
                note={salesOrder?._note}
                additionalNotes={salesOrder._additionalNotes}
                expanded={false}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default ReturnRefundForm
