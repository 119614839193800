import React, { useEffect, useState } from 'react'

import { Checkroom, SellOutlined } from '@mui/icons-material'
import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

import { currencyFormatter } from '../../utils'
import FulfillmentStatus from '../fulfillment/FulfillmentStatus'
import TrackingInfo from '../fulfillment/TrackingInfo'

import StatusInfoErrorIcon from './StatusInfoErrorIcon'
import StatusInfoLoadingIcon from './StatusInfoLoadingIcon'

const OrderItems = ({
  orderItems,
  status,
  extraStatus,
  trackingInfo,
  canDuplicateOrder,
  selectedItems,
  order,
  omsLoading,
  omsErrorStatus,
  customer,
  handleItemQuantity,
}: any) => {
  const duplicateOrderMode = window.location.pathname.includes('duplicate')
  const refundMode = window.location.pathname.includes('refund')

  const validatePositiveInteger = (e: { code: string; preventDefault: () => void }) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }

  const isSelected = () => {
    let selected = false
    orderItems.some((element: any) => {
      if (
        ((element.currentShopifyQuantity > 0 && (status === 'Fulfilled' || status === 'Return')) ||
          (element.quantity > 0 && status === 'Unfulfilled')) &&
        element.quantityInventory > 0 &&
        !element.isGiftCard
      ) {
        selected = true
      }
    })
    return selected
  }
  const totalLineItemPrice = (params: any) => {
    const fulfilledQuanity =
      params.row.quantityInventory <= 0 && duplicateOrderMode
        ? 0
        : params.row.currentShopifyQuantity
    const quantity = status === 'Fulfilled' ? fulfilledQuanity : params.row.quantity
    // initialQuantity was automatically reduced when a line item was removed from the order
    // the initialQuantity needs to add an nonFulfillableQuantity
    const price =
      parseFloat(params.row.discountedPrice).toFixed(2) !== '0.00'
        ? params.row.tmpPrice -
          params.row.discountedPrice /
            (params.row.initialQuantity + params.row.nonFulfillableQuantity)
        : params.row.tmpPrice
    const formattedPrice = currencyFormatter(customer, order).format(price * quantity)
    return order?._currencyCode !== 'USD'
      ? formattedPrice + ' ' + order?._currencyCode
      : formattedPrice
  }

  const getCurrencySymbol = () => {
    return order?._currencyCode !== 'USD' ? ' ' + order?._currencyCode : ''
  }

  const ItemQuantity = ({ params }: any) => {
    const quantity =
      !omsLoading && params.row.shipMemoId
        ? params.row.omsQuantity.toString()
        : status === 'Fulfilled'
        ? params.row.currentShopifyQuantity.toString()
        : params.row.quantity.toString()
    return <Typography>{quantity}</Typography>
  }

  const ItemQuantitySelect = ({ params }: any) => {
    const [quantity, setQuantity] = useState<string>(
      (status === 'Fulfilled' || status === 'Return') && refundMode
        ? params.row.currentShopifyQuantity
        : params.row.quantityInventory > 0 && duplicateOrderMode
        ? (params.row.currentShopifyQuantity - params.row.returnQuantity).toString()
        : params.row.quantityInventory <= 0 && duplicateOrderMode
        ? '0'
        : params.row.quantity.toString(),
    )

    const limitedQuanity = duplicateOrderMode
      ? params.row.initialQuantity - params.row.returnQuantity
      : params.row.initialQuantity

    useEffect(() => {
      if (refundMode) {
        handleItemQuantity()
      }
      // Avoid negative quantities in the input box when deselecting items
      if (parseInt(quantity) < 0) {
        setQuantity('0')
      }
    }, [quantity])

    return (
      <Box key={params.row.id}>
        <TextField
          id='outlined-number'
          type='number'
          disabled={
            (params.row.quantityInventory <= 0 && !refundMode) ||
            (params.row.refundableQuantity <= 0 && refundMode) ||
            (params.row.initialQuantity - params.row.returnQuantity === 0 && refundMode) ||
            (params.row.isGiftCard && duplicateOrderMode) ||
            !canDuplicateOrder
              ? true
              : false
          }
          onChange={(e) => {
            if (
              duplicateOrderMode ||
              (refundMode && (status === 'Fulfilled' || status === 'Return'))
            ) {
              if (e.target.value <= limitedQuanity) {
                e.target.value !== ''
                  ? (params.row.currentShopifyQuantity = parseInt(e.target.value))
                  : null
                setQuantity(e.target.value)
              } else {
                params.row.currentShopifyQuantity = params.row.initialQuantity
              }
            }
            if (refundMode && status === 'Unfulfilled') {
              if (e.target.value <= params.row.initialQuantity) {
                e.target.value !== '' ? (params.row.quantity = parseInt(e.target.value)) : null
                setQuantity(e.target.value)
              } else {
                params.row.quantity = params.row.initialQuantity
              }
            }
            selectedItems(isSelected())
          }}
          value={quantity}
          size='small'
          InputProps={{
            inputProps: {
              min: 0,
              max: limitedQuanity,
            },
            endAdornment: (
              <InputAdornment position='end'>
                <Typography>of {params.row.initialQuantity - params.row.returnQuantity}</Typography>
              </InputAdornment>
            ),
          }}
          onKeyDown={validatePositiveInteger}
          helperText={
            duplicateOrderMode &&
            params.row.returnQuantity > 0 &&
            `${params.row.returnQuantity} in returns`
          }
          FormHelperTextProps={{
            sx: {
              fontVariant: 'caption',
              fontStyle: 'italic',
              marginTop: 0,
              marginRight: 0,
              textAlign: 'right',
              height: 0,
            },
          }}
        />
      </Box>
    )
  }

  const discountPrice = (params: any) => {
    const price: any = parseFloat(params.row.discountedPrice).toFixed(2)
    return ' Discount: -' + currencyFormatter(customer, order).format(price)
  }

  const DiscountedPrice = ({ params }: any) => {
    return (
      <Typography>
        {parseFloat(params.row.discountedPrice).toFixed(2) === '0.00'
          ? currencyFormatter(customer, order).format(params.row.tmpPrice)
          : currencyFormatter(customer, order).format(
              params.row.tmpPrice -
                params.row.discountedPrice /
                  (params.row.initialQuantity + params.row.nonFulfillableQuantity),
            )}
        {getCurrencySymbol()} x{' '}
      </Typography>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'Product',
      flex: 1,
      minWidth: 350,
      maxWidth: 600,
      renderCell: (params) => (
        <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
          <Grid item xs={3}>
            {params.row.srcImage ? (
              <img
                src={params.row.srcImage}
                style={{
                  height: '100%',
                  width: '100%',
                  maxHeight: '100px',
                  maxWidth: '100px',
                  minHeight: '80px',
                  minWidth: '80px',
                }}
                alt={params.row.variantTitle}
              />
            ) : (
              <Checkroom />
            )}
          </Grid>
          <Grid item padding={'1rem'} xs={9}>
            <Typography>{params.row.title}</Typography>
            <Typography>{params.row.variantTitle}</Typography>
            <Typography>SKU: {params.row.sku}</Typography>
            {params.row.quantityInventory <= 0 &&
            !params.row.isGiftCard &&
            !window.location.pathname.includes('refund') ? (
              <Typography variant='caption' fontStyle='italic' sx={{ color: 'error.main' }}>
                Item Out of Stock
              </Typography>
            ) : null}
            {parseFloat(params.row.discountedPrice).toFixed(2) !== '0.00' ? (
              <Grid container direction='row' alignItems='center'>
                <Grid item>
                  <SellOutlined sx={{ width: '15px' }} />
                </Grid>
                <Grid item>
                  <Typography variant='caption' sx={{ fontStyle: 'italic', fontSize: 12.5 }}>
                    {order._discountCode} ({discountPrice(params)})
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Quantity',
      flex: 1,
      minWidth: 250,
      align: 'right',
      renderCell: (params) => (
        <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
          <Typography sx={{ textDecoration: 'line-through' }}>
            {parseFloat(params.row.discountedPrice).toFixed(2) !== '0.00' &&
              currencyFormatter(customer, order).format(params.row.tmpPrice) + getCurrencySymbol()}
          </Typography>
          <Grid item padding={'0.5rem'}>
            <DiscountedPrice params={params} />
          </Grid>
          {duplicateOrderMode || refundMode ? (
            <ItemQuantitySelect params={params} />
          ) : (
            <ItemQuantity params={params} />
          )}
        </Grid>
      ),
    },
    {
      field: 'Total',
      flex: 1,
      align: 'right',
      renderCell: (params) => (
        <Box>
          <Typography>{totalLineItemPrice(params)} </Typography>
        </Box>
      ),
    },
  ]

  const CardHeader = ({ length }: any) => {
    return (
      <Typography variant='h2' fontSize={'1.25rem'} fontWeight={'bold'}>
        {status} ({length}) <small style={{ fontWeight: 'normal' }}>{extraStatus}</small>
      </Typography>
    )
  }

  return (
    <Grid>
      <Grid>
        {status === 'Fulfilled' ||
        status === 'Return' ||
        status === 'Returned' ||
        status === 'Return in progress' ||
        status === 'Removed' ||
        (status === 'Unfulfilled' && order?._cancelledAt !== null) ? (
          <Grid
            sx={{ padding: '20px' }}
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
          >
            {status === 'Return' ? (
              <Typography
                variant='h2'
                fontSize={'1.25rem'}
                fontWeight={'bold'}
                sx={{ textTransform: 'capitalize' }}
              >
                {extraStatus} ({orderItems.length})
              </Typography>
            ) : (
              <CardHeader length={orderItems.length} />
            )}
          </Grid>
        ) : null}
        {omsLoading &&
        !omsErrorStatus &&
        status === 'Unfulfilled' &&
        order?._cancelledAt === null ? (
          <Grid
            sx={{ padding: '20px' }}
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
          >
            <CardHeader length={orderItems.length} />
            <StatusInfoLoadingIcon />
          </Grid>
        ) : null}
        {!omsLoading &&
        omsErrorStatus &&
        status === 'Unfulfilled' &&
        order?._cancelledAt === null ? (
          <Grid
            sx={{ padding: '20px' }}
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
          >
            <CardHeader length={orderItems.length} />
            <StatusInfoErrorIcon />
          </Grid>
        ) : null}
        {!omsLoading &&
        !omsErrorStatus &&
        status === 'Unfulfilled' &&
        order?._cancelledAt === null ? (
          <FulfillmentStatus
            status={order?._omsStatus}
            shipUpdates={order?._upsShipUpdates.filter((element: any) => {
              return orderItems.find(
                (element1: any) => element1.shipMemoId === Object.entries(element)[0][0],
              )
            })}
            quantity={orderItems.length}
            shipMemo={order?._shipMemos.filter((element: any) => {
              return orderItems.find(
                (element1: any) =>
                  element.CLU.toLowerCase() === element1.sku.toLowerCase() &&
                  element1.shipMemoId === element.id,
              )
            })}
          />
        ) : null}
        <TrackingInfo trackingInfo={trackingInfo} />
      </Grid>
      <DataGrid
        sx={{
          border: 'none',
          padding: '20px',
        }}
        rows={orderItems}
        getRowId={(orderItem) => orderItem.id}
        columns={columns}
        autoHeight={true}
        hideFooter={true}
        getRowHeight={() => 'auto'}
        headerHeight={0}
      />
    </Grid>
  )
}

export default OrderItems
