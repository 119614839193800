import React, { Dispatch, SetStateAction, useEffect } from 'react'

import { Grid, FormControl, MenuItem, Select, Typography } from '@mui/material'

type ShippingMethodSelectorProps = {
  initialShippingMethod: any
  onSelect: Dispatch<SetStateAction<any>>
}

const ShippingMethodSelector = ({
  initialShippingMethod,
  onSelect,
}: ShippingMethodSelectorProps) => {
  const availSM = ['UPS Standard', 'Express Shipping', 'Priority Shipping', 'Economy']

  useEffect(() => {
    if (initialShippingMethod?.trim() !== '') {
      onSelect('UPS Standard')
    }
  }, [])

  const handleSelect = (event: any) => {
    onSelect(event.target.value)
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-end'
      alignItems='flex-end'
      spacing={2}
      sx={{
        paddingBottom: 1,
        paddingTop: 1,
      }}
    >
      <Grid
        sx={{
          paddingBottom: '15px',
        }}
      >
        <Typography variant='body1' fontSize={18}>
          Shipping Method
        </Typography>
      </Grid>
      <Grid>
        <FormControl
          sx={{ m: 1, minWidth: 120, background: 'white', borderRadius: '4px', marginRight: '0px' }}
          size='small'
        >
          <Select
            displayEmpty
            value={availSM.includes(initialShippingMethod) ? initialShippingMethod : 'UPS Standard'}
            onChange={handleSelect}
          >
            {availSM.map((sm) => (
              <MenuItem key={sm} value={sm}>
                {sm}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default ShippingMethodSelector
