/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'

import { Checkroom } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, Grid, List, ListItem, Modal, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useSelector } from 'react-redux'

import { SalesItem } from '../../../models/SalesItem'
import { currencyFormatter } from '../../../utils'
import { style } from '../../orders/PaymentCard'

const initHeader = 'Return Confirmation'

type Props = {
  isShown: boolean
  onClose: any
  orderItems: SalesItem[]
  amount: number
  rmaNumber: string
  actionStatus: string
  taxPrice: number
}

const ReturnModal = ({
  orderItems,
  isShown,
  onClose,
  amount,
  rmaNumber,
  actionStatus,
  taxPrice,
}: Props) => {
  const [open, setOpen] = useState<boolean>(isShown)
  const [loading, setLoading] = useState<boolean>(false)
  const [header, setHeader] = useState<string>(initHeader)
  const [message, setMessage] = useState<string>()
  const [state, setState] = useState<'init' | 'error' | 'success'>('init')
  const order = useSelector((state: any) => state.order.value)
  const customer = useSelector((state: any) => state.order.customer)

  const style1 = (tmpWidth: number) => {
    return {
      textAlign: 'center',
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: tmpWidth,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
    }
  }

  useEffect(() => {
    setOpen(isShown)
    if (isShown) {
      initState()
    }
  }, [isShown])

  const initState = () => {
    if (actionStatus === 'approved') {
      setMessage(`Are you sure you want to approve the refund?`)
    } else if (actionStatus === 'rejected') {
      setMessage(`Are you sure you want to reject the refund?`)
    } else if (actionStatus === 'resolve_manually_without_automation') {
      setMessage(`Are you sure you want to manually resolve the refund?`)
    }
    setState('init')
    setHeader(initHeader)
  }

  const createReturnAction = () => {
    setLoading(true)
    if (amount > 0) {
      const requestPayload: {
        [key: string]: any
      } = {}

      requestPayload['action'] = actionStatus
      requestPayload['amount'] = amount
      requestPayload['order_id'] = order?._id
      requestPayload['rma_number'] = rmaNumber
      if (orderItems.length > 0) {
        requestPayload['items'] = orderItems
      }
      fetchAuthSession()
        .then((session) => {
          const accesToken = session.tokens?.accessToken?.toString()
          return fetch(`${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/returns/action/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accesToken}`,
            },
            body: JSON.stringify(requestPayload),
          })
        })
        .then(async (response) => {
          if (response.status === 200) {
            setLoading(false)
            setState('success')
          } else {
            const data = await response.json()
            const hasDetail = Object.prototype.hasOwnProperty.call(data, 'detail')
            if (hasDetail) {
              setMessage(data.detail)
            }
            setState('error')
            setHeader('Woops')
            setLoading(false)
          }
        })
        .catch((err: any) => {
          console.error('error', err)
          setMessage('Unable to process return action, please try again later.')
          setHeader('Woops')
          setState('error')
          setLoading(false)
        })
    }
  }

  const CloseRow = () => {
    return (
      <React.Fragment>
        <Grid item paddingBottom={2}>
          <Typography variant='body1'>{message}</Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='neutral' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </React.Fragment>
    )
  }

  const closeSuccessModal = () => {
    onClose()
    location.reload()
  }

  const SuccessRow = () => {
    return (
      <React.Fragment>
        <Grid>
          <Typography variant='body1' paddingBottom={2}>
            Successfully{' '}
            {actionStatus === 'resolve_manually_without_automation' ? ' resolved' : actionStatus}{' '}
            the {formattedPrice(amount.toString())} refund
          </Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='neutral' onClick={closeSuccessModal}>
            Close
          </Button>
        </Grid>
      </React.Fragment>
    )
  }

  const getTotalOfItems = (totalOfItems: number) => {
    return totalOfItems > 1 ? totalOfItems + ' items' : totalOfItems + ' item'
  }

  const FormRow = () => {
    return (
      <React.Fragment>
        {orderItems.length > 0 && (
          <div
            style={{
              width: '100%',
              overflowX: 'scroll',
              maxHeight: 400,
            }}
          >
            <DataGrid
              sx={{
                border: 'none',
                padding: '20px',
                width: '100%',
                overflowX: 'scroll',
              }}
              rows={orderItems}
              getRowId={(orderItem) => orderItem.idx}
              columns={columns}
              autoHeight={true}
              hideFooter={true}
              getRowHeight={() => 'auto'}
              headerHeight={0}
            />
            <Divider style={{ width: '100%' }} />
          </div>
        )}
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          spacing={2}
          sx={{
            margin: '15px auto',
            flexGrow: 1,
          }}
          paddingLeft={1}
          paddingRight={3}
          paddingBottom={1}
        >
          <List sx={style}>
            <TotalLineItemPrice
              text1={'Item subtotal'}
              text2={getTotalOfItems(orderItems.length)}
              text3={formattedPrice(amount)}
            />
            <TotalLineItemPrice text1={'Tax'} text3={formattedPrice(taxPrice)} />
            <TotalLineItemPrice text1={'Refund amount'} text3={formattedPrice(amount.toString())} />
          </List>
          <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
            {message}
          </Typography>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={6} paddingLeft={0}>
            <Button variant='contained' color='neutral' onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} paddingLeft={0}>
            <LoadingButton
              type='submit'
              color='primary'
              size='medium'
              loading={loading}
              variant='contained'
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
              onClick={createReturnAction}
            >
              Confirm
            </LoadingButton>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const ConditionalRow = () => {
    if (state === 'init') {
      return <FormRow />
    } else if (state === 'success') {
      return <SuccessRow />
    } else {
      return <CloseRow />
    }
  }

  const formattedPrice = (price: any) => {
    const formattedPrice = currencyFormatter(customer, order).format(price)
    return order?._currencyCode !== 'USD'
      ? formattedPrice + ' ' + order?._currencyCode
      : formattedPrice
  }

  const getCurrencySymbol = () => {
    return order?._currencyCode !== 'USD' ? ' ' + order?._currencyCode : ''
  }

  const columns: GridColDef[] = [
    {
      field: 'Product',
      flex: 1,
      minWidth: 350,
      maxWidth: 600,
      renderCell: (params) => (
        <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
          <Grid item xs={3}>
            {params.row.product_image ? (
              <img
                src={params.row.product_image}
                style={{
                  height: '100%',
                  width: '100%',
                  maxHeight: '100px',
                  maxWidth: '100px',
                  minHeight: '80px',
                  minWidth: '80px',
                }}
                alt={params.row.name}
              />
            ) : (
              <Checkroom />
            )}
          </Grid>
          <Grid item padding={'1rem'} xs={9}>
            <Typography>{params.row.name.split(' - ')[0]}</Typography>
            <Typography>{params.row.name.split(' - ')[1]}</Typography>
            <Typography>SKU: {params.row['sku']}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Quantity',
      flex: 1,
      minWidth: 100,
      align: 'right',
      renderCell: (params) => (
        <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
          <Grid item padding={'0.5rem'}>
            <Typography>
              {formattedPrice(params.row.unit_price) +
                getCurrencySymbol() +
                ' x ' +
                params.row.quantity}
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Unit price',
      flex: 1,
      align: 'right',
      renderCell: (params) => (
        <Box>
          <Typography>
            {formattedPrice(parseFloat(params.row.unit_price) * params.row.quantity)}{' '}
          </Typography>
        </Box>
      ),
    },
  ]

  const TotalLineItemPrice = ({ text1, text2, text3, fontWeight = 400 }: any) => {
    return (
      <ListItem>
        <Grid item xs={3}>
          <Typography sx={{ fontWeight: fontWeight }}>{text1}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography sx={{ fontWeight: fontWeight }}>{text2}</Typography>
        </Grid>
        <Grid item xs={4} justifyContent='flex-end'>
          <Typography textAlign='right' sx={{ fontWeight: fontWeight }}>
            {text3}
          </Typography>
        </Grid>
      </ListItem>
    )
  }

  return (
    <Modal
      id='refund-order-confirmation-modal'
      open={open}
      aria-labelledby='refund-order-modal-title'
      aria-describedby='refund-order-modal-description'
      onClose={onClose}
    >
      <Box sx={style1(state === 'init' ? 700 : 400)} id='refund-order-confirmation-box'>
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
          <Grid item>
            <Typography variant='h2' fontSize={'1.5rem'}>
              {header}
            </Typography>
          </Grid>
          <Grid item width={'100%'}>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
              <ConditionalRow />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default ReturnModal
