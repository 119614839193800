/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'

import { Box, Button, Grid, Paper, FormControlLabel, Checkbox, Alert } from '@mui/material'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { SalesItem } from '../../models/SalesItem'
import { SalesOrder } from '../../models/SalesOrder'
import { orderUpdated } from '../../store/orderSlice'
import Loading from '../base/Loading'
import ShippingMethodSelector from '../fulfillment/ShippingMethodSelector'

import CustomerData from './CustomerData'
import DuplicateOrderModal from './DuplicateOrderModal'
import NotesStacked from './NotesStacked'
import OrderActions from './OrderActions'
import OrderItems from './OrderItems'
import OrderMetaData from './OrderMetaData'
import PaymentCard from './PaymentCard'
import TagsStacked from './TagsStacked'

const OrderDuplicateForm = () => {
  const { orderName } = useParams()
  const navigate = useNavigate()
  const [showLoading, setLoading] = useState<boolean>(true)
  const [isDuplicateOrderModalVisible, setDuplicateOrderModalVisibility] = useState<boolean>(false)
  const [selected, setSelected] = useState<boolean>(true)
  const [shippingMethod, setShippingMethod] = useState<string>()
  const dispatch = useDispatch()
  const tmpOrderName = useSelector((state: any) => state.order.name)
  const tmpCanDuplicateOrder = useSelector((state: any) => state.order.canDuplicateOrder)
  const order = useSelector((state: any) => state.order.value)
  const nameList = useSelector((state: any) => state.order.nameList)
  const trackingList = useSelector((state: any) => state.order.trackingList)
  const customer = useSelector((state: any) => state.order.customer)
  const fulfilledOrderItems = useSelector((state: any) => state.order.fulfilledOrderItems)
  const [salesOrder, setSalesOrder] = useState<SalesOrder | null>(order)
  const [canDuplicateOrder, setCanDuplicate] = useState<boolean>(tmpCanDuplicateOrder)

  useEffect(() => {
    fulfilledOrderItems.map((object: any) => {
      object.map((itemsList: any) => {
        selected
          ? (itemsList.currentShopifyQuantity = itemsList.initialQuantity)
          : (itemsList.currentShopifyQuantity = 0)
      })
    })
    setSalesOrder(order)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (tmpOrderName !== orderName || !canDuplicateOrder) {
      navigate(`/orders/${orderName}/`)
    }
  }, [location.pathname])

  const handleItemQuantitySelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.checked)
    fulfilledOrderItems.map((object: SalesItem[]) => {
      object.map((itemsList: SalesItem) => {
        event.target.checked
          ? (itemsList.currentShopifyQuantity = itemsList.initialQuantity)
          : (itemsList.currentShopifyQuantity = 0)
      })
    })
  }

  const handleDuplicateOrderModalVisibility = () => {
    setDuplicateOrderModalVisibility(!isDuplicateOrderModalVisible)
  }

  const hasDuplicateTag = (tags: string) => {
    return tags.includes('DUPLICATED') || tags.includes('CS DUPLICATED ORDER')
  }

  const fetchOrderDetails = async () => {
    const { tokens } = await fetchAuthSession()
    const accessToken = tokens?.accessToken.toString()
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/order/shopify/${orderName}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      const responseData = await response.json()
      const tmpOrder = responseData.order
      if (hasDuplicateTag(tmpOrder.tags)) {
        order._tags = tmpOrder.tags
        order._additionalNotes = tmpOrder.additional_notes
        setSalesOrder(order)
        setCanDuplicate(false)
        dispatch(
          orderUpdated({
            type: 'duplicate',
            value: order,
            canDuplicateOrder: false,
          }),
        )
      }
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const DuplicateAlert = () => {
    let message = 'Cannot duplicate order'
    if (hasDuplicateTag(order?._tags)) {
      message = 'Cannot duplicate an order that has been duplicated'
    }
    return (
      <Grid m={2}>
        <Alert severity='warning'>{message}</Alert>
      </Grid>
    )
  }

  return (
    <Box
      sx={{
        p: 4,
        marginLeft: '-32px',
        paddingBottom: '120px',
      }}
    >
      {showLoading && <Loading />}
      <DuplicateOrderModal
        isShown={isDuplicateOrderModalVisible}
        onClose={handleDuplicateOrderModalVisibility}
        onSubmitSuccess={fetchOrderDetails}
        customer={customer}
        order={salesOrder}
        orderItems={fulfilledOrderItems}
        shippingTitle={shippingMethod}
      />
      {!showLoading && salesOrder && (
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          columns={12}
          spacing={2}
          sx={{
            margin: 'auto',
            maxWidth: 1400,
            flexGrow: 1,
          }}
        >
          <Grid container alignItems='stretch' direction='column' justifyContent='flex-end'>
            <Grid display='flex' alignItems='flex-end' justifyContent='flex-end'>
              <OrderActions />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={8}>
            <Paper
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
              }}
            >
              <OrderMetaData order={salesOrder} />
              <Grid
                container
                direction='column'
                justifyContent='flex-end'
                alignItems='flex-end'
                spacing={2}
              >
                <FormControlLabel
                  control={<Checkbox checked={selected} onChange={handleItemQuantitySelection} />}
                  label={selected ? 'Deselect All' : 'Select All'}
                  labelPlacement='end'
                />
              </Grid>
              {fulfilledOrderItems.length > 0 &&
                fulfilledOrderItems.map((fulfilledOrderItem: any, index: any) => (
                  <Grid key={index}>
                    <OrderItems
                      trackingInfo={trackingList[index]}
                      orderItems={fulfilledOrderItem}
                      status={'Fulfilled'}
                      extraStatus={nameList[index]}
                      canDuplicateOrder={canDuplicateOrder}
                      selectedItems={setSelected}
                      order={salesOrder}
                      customer={customer}
                    />
                    {!canDuplicateOrder ? <DuplicateAlert /> : null}
                  </Grid>
                ))}
              <Grid
                container
                direction='row'
                justifyContent='flex-end'
                alignItems='flex-end'
                padding={2}
              >
                <ShippingMethodSelector
                  initialShippingMethod={shippingMethod}
                  onSelect={setShippingMethod}
                />
                <Button
                  variant='contained'
                  size='large'
                  onClick={handleDuplicateOrderModalVisibility}
                  disabled={!canDuplicateOrder || !selected}
                >
                  Create duplicate order
                </Button>
              </Grid>
            </Paper>
            <Paper
              sx={{
                paddingBottom: '10px',
                margin: '20px 0px 12px ',
              }}
            >
              <PaymentCard order={salesOrder} customer={customer} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={4}>
            <Paper
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
              }}
            >
              <CustomerData customer={customer} />
              <TagsStacked tags={salesOrder._tags} />
            </Paper>
            <Paper
              sx={{
                p: 2,
                margin: '10px auto',
                flexGrow: 1,
              }}
            >
              <NotesStacked
                note={salesOrder?._note}
                additionalNotes={salesOrder._additionalNotes}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default OrderDuplicateForm
